import React from "react";
import SEO from "../components/seo";
import BaseLayout from "../components/BaseLayout";
import { $ThankYou } from "../styles/SC_ThankYou";

function ThankYouPage({ pageContext: { fechedOfferList } }) {
  const isBrowser = () => typeof window !== "undefined";
  isBrowser() && window.location.assign("https://www.pfresolu.com/Carrieres/");
  return (
    <>
      <BaseLayout>
        <SEO description="Merci" title="Resolu" />

        <$ThankYou>
          <div>
            <h1>Candidature reçue!</h1>
            <p>
              Merci de votre intérêt pour un emploi chez Produits Forestiers
              Résolu.
            </p>
            <span className="postulationCTA">
              <a href="/">Retour à la page d'accueil</a>
            </span>
          </div>
        </$ThankYou>
      </BaseLayout>
    </>
  );
}

export default ThankYouPage;
